/* @jsx jsx */

import { jsx } from "theme-ui";
import { PageProps } from "gatsby";
import { Flex } from "../components/Flex";
import { useEffect } from "react";
import WidgetPage from "./widget";

export default (props: PageProps) => {
  useEffect(() => {
    window.addEventListener("message", async (e) => {
      if (e.origin.indexOf(window.location.origin) === 0) {
        if (e.data === "close") {
          window.close();
          return;
        }
      }
    });
  }, []);

  return (
    <Flex
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        width: `100vw`,
        height: `100vh`,
        p: 4,
        backgroundColor: `gray.1`,
      }}
    >
      <WidgetPage {...props} />
    </Flex>
  );
};
