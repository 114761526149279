/* @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { Flex as Reflex, BoxProps } from "reflexbox";

export const Flex = (
  props: BoxProps & {
    gap?: number;
    children: React.ReactNode;
  }
) => {
  let { children, ...rest } = props;

  return jsx(
    Reflex,
    {
      ...rest,
      sx: {
        "> :not(:first-child)": {
          [props.flexDirection === "column"
            ? "marginTop"
            : "marginLeft"]: props.gap,
        },
      },
    },
    children
  );
};
